export const useSidebarStore = defineStore("sidebarStore", {
  state: () => ({
    openedPanels: [],
    selectedItem: {},
    isOpen: true,
  }),
  getters: {
    getOpenedPanels: (state) => state.openedPanels,
    getIsOpen: (state) => state.isOpen,
  },
  actions: {
    addOpenedPanel(payload) {
      this.openedPanels.push(payload);
    },
    setSelectedItem(payload) {
      this.selectedItem = payload;
    },
    getSelectedItem() {
      return this.selectedItem;
    },
    removeOpenedPanel(payload) {
      const newSelectedItems = this.openedPanels.filter((item) => item?.key !== payload?.key);
      this.openedPanels = newSelectedItems;
    },
    toggleSidebar() {
      this.isOpen = !this.isOpen;
    },
    closeSidebar() {
      this.isOpen = false;
    },
    openSidebar() {
      this.isOpen = true;
    },
  },
  persist: true,
});
